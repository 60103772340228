import clsx from 'clsx';

import styles from './InternalMenu.module.scss';
import { InternalMenuItem } from './InternalMenuItem';

export const NilInternalMenu = [
  { key: 1, name: 'nil news', href: '/nil/news/', paramName: '/nil/news/' },
  {
    key: 2,
    name: 'valuations / rankings',
    href: '/nil/rankings/',
    paramName: 'rankings',
  },
  {
    key: 3,
    name: 'deal tracker',
    href: '/nil/deals/',
    paramName: 'nil/deals',
  },
  {
    key: 4,
    name: 'collectives',
    href: '/nil/collectives/',
    paramName: 'collectives',
  },
  {
    key: 5,
    name: 'compliance / resources',
    href: '/nil/resources/news/',
    paramName: 'laws',
  },
];

export const TeamRosterInternalMenu = (
  college: string,
  sport: string,
  year: string,
  /**To account for being a year behind other lists */
  isRoster?: boolean,
) => {
  const rosterYear = isRoster ? year : +year - 1;
  const commitYear = isRoster ? +year + 1 : year;
  const basePath = `/college/${college}/${sport}/${commitYear}`;

  return [
    {
      key: 1,
      name: 'all commits',
      href: `${basePath}/industry-comparison-commits/`,
      paramName: 'industry-comparison-commits',
    },
    {
      key: 2,
      name: 'signed',
      href: `${basePath}/commits/?status=signed`,
      paramName: 'signed',
    },
    {
      key: 3,
      name: 'enrolled',
      href: `${basePath}/commits/?status=enrolled`,
      paramName: 'enrolled',
    },
    {
      key: 4,
      name: 'transfers',
      href: `/college/${college}/${sport}/${commitYear}/transfers/`,
      paramName: 'transfer',
    },
    {
      key: 5,
      name: 'roster',
      href: `/college/${college}/${sport}/${rosterYear}/roster/`,
      paramName: 'roster',
    },
  ];
};

export interface IInternalMenuItemProps {
  key: number;
  name: string;
  href: string;
  paramName: string;
}
interface IInternalMenuProps {
  list: IInternalMenuItemProps[];
  className?: string;
}
export const InternalMenu = ({ list, className }: IInternalMenuProps) => {
  return (
    <ul className={clsx(styles.internalContainer, className)}>
      {list.map((item) => {
        return <InternalMenuItem {...item} key={item.key} />;
      })}
    </ul>
  );
};
