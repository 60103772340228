import { PaginationProps } from '@mui/lab';
import { useMediaQuery, useTheme } from '@mui/material';
import { Pagination as PaginationComponent } from '@mui/material';
import clsx from 'clsx';

import styles from './Pagination.module.scss';

interface IPaginationProps extends PaginationProps {
  className?: string;
}

export const Pagination = ({ className, ...props }: IPaginationProps) => {
  const theme = useTheme();
  const count = useMediaQuery(theme.breakpoints.down('md'));
  const siblingCountSize = count ? 0 : 4;

  return (
    <PaginationComponent
      className={clsx(styles.paginationContainer, className)}
      color="primary"
      shape="rounded"
      siblingCount={siblingCountSize}
      variant="outlined"
      {...props}
    />
  );
};
