import { useSite } from '@on3/ui-lib/index';
import { TextLink } from 'components/UI/Link/TextLink';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { IInternalMenuItemProps } from './InternalMenu';
import styles from './InternalMenu.module.scss';

export const InternalMenuItem = ({
  name,
  href,
  paramName,
}: IInternalMenuItemProps) => {
  const { currentSite } = useSite();

  const primaryColor = currentSite?.linkColor || '#fe3b1f';
  const router = useRouter();

  const isCategory =
    router.asPath.includes('resources') && paramName === 'laws';
  const isCollectiveDeals =
    router.pathname === `/nil/collectives/[collectiveGroup]/deals`;
  const isActive =
    (isCollectiveDeals && paramName === 'collectives') ||
    (router.pathname !== `/nil/collectives/[collectiveGroup]/deals` &&
      router.asPath.includes(paramName));
  const textColor = isActive || isCategory ? primaryColor : '#6f747b';
  const borderColor = isActive || isCategory ? primaryColor : '#f5f6f9';
  const linkStyles = useMemo(
    () => ({ borderColor: `${borderColor}`, color: `${textColor}` }),
    [borderColor, textColor],
  );

  return (
    <li className={styles.internalItem}>
      <TextLink
        className={styles.internalLink}
        href={href}
        style={linkStyles}
        underline="none"
        variant="subtitle1"
      >
        {name}
      </TextLink>
    </li>
  );
};
