import clsx from 'clsx';
import { TextLink } from 'components/UI/Link/TextLink';
import { useRouter } from 'next/router';

import type { IInternalBlockMenuItemProps } from './InternalBlockMenu';
import styles from './InternalBlockMenu.module.scss';

export const InternalBlockMenuItem = ({
  name,
  href,
  paramName,
  isDark,
  className,
  activeClassName,
}: IInternalBlockMenuItemProps) => {
  const router = useRouter();
  const isActive = router.asPath.includes(paramName);

  return (
    <li className={styles.internalItem}>
      <TextLink
        className={clsx(styles.internalLink, className, {
          [activeClassName ?? styles.active]: isActive,
          [styles.dark]: isDark,
        })}
        href={href}
        underline="none"
        variant="subtitle1"
      >
        {name}
      </TextLink>
    </li>
  );
};
