import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';

import styles from './StandardSidebar.module.scss';

interface StandardSidebarProps {
  children?: React.ReactNode;
}

export const StandardSidebar = ({ children }: StandardSidebarProps) => {
  return (
    <aside className={styles.sidebar}>
      <MPU id="mpu_top" />
      {children}
      <MobileMPU id="mobile_mpu_middle" incontent />
      <MPU className={styles.sticky} id="mpu_middle" />
    </aside>
  );
};
