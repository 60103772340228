import type {
  HeadlineResponse,
  HeadlineResponsePagedData,
  SiteCategoryResponse,
} from '@on3/api';
import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { ArticleFeed } from 'components/Headlines/ArticleFeed/ArticleFeed';
import { RightSidebarLayout } from 'components/Layouts/RightSidebarLayout';
import { StandardSidebar } from 'components/Layouts/StandardSidebar';
import {
  AboutBlockMenu,
  InternalBlockMenu,
} from 'components/Menu/InternalBlockMenu';
import { InternalMenu, NilInternalMenu } from 'components/Menu/InternalMenu';
import { Pagination } from 'components/UI/Pagination/Pagination';
import { Text } from 'components/UI/Typography/Text';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { initializeAds } from 'utils/ads/ads';
import { useAds } from 'utils/hooks/useAds';

import styles from './CategoryPage.module.scss';

export interface ICategoryProps {
  articles: HeadlineResponsePagedData;
  category: SiteCategoryResponse;
  pageTitle?: string;
}

export const CategoryPage = ({
  articles,
  category,
  pageTitle,
}: ICategoryProps) => {
  const { list, pagination } = articles;
  const { currentSite } = useSite();
  const { user } = useAuth();
  const [pageNumber, setPageNumber] = useState(pagination?.currentPage);
  const router = useRouter();
  const passedTeam = currentSite?.isNational ? 'on3' : currentSite?.slug;
  const { categoryName } = category;
  const showSubMenu = currentSite?.name === 'NIL';
  const isPressFeed = router.asPath === '/category/press/news/';
  // On3 Her or Softball America
  const tags =
    currentSite?.key === 400 || currentSite?.key === 401
      ? 'womens-sports'
      : undefined;
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPageNumber(value);
      router.push({
        pathname: router.pathname,
        query: { ...router.query, page: value },
      });
      if (!user?.st?.includes('premium')) {
        initializeAds({
          pageType: 'category',
          team: passedTeam,
          siteType: currentSite?.type,
          siteKey: currentSite?.key,
          tags,
          user,
        });
      }
    },
    [currentSite?.key, currentSite?.type, passedTeam, router, tags, user],
  );

  useAds({ pageType: 'category', tags });

  return (
    <RightSidebarLayout>
      <section className={styles.container}>
        <header className={styles.header}>
          <Text variant="h1">
            {pageTitle
              ? pageTitle
              : `${categoryName}${
                  !categoryName?.includes('News') ? ' News' : ''
                }`}
          </Text>
        </header>
        {showSubMenu && <InternalMenu list={NilInternalMenu} />}
        {isPressFeed && <InternalBlockMenu list={AboutBlockMenu} />}
        <Pagination
          count={pagination?.pageCount}
          onChange={handleChange}
          page={pageNumber}
        />

        {list && list?.length > 0 ? (
          list?.map((item: HeadlineResponse) => {
            if (!item?.key) {
              return null;
            }

            return <ArticleFeed {...item} key={item?.key} />;
          })
        ) : (
          <div className={styles.noarticles}>
            <Text paragraph variant="body1">
              There are no articles for {categoryName}.
            </Text>
          </div>
        )}
        <Pagination
          count={pagination?.pageCount}
          onChange={handleChange}
          page={pageNumber}
        />
      </section>
      <StandardSidebar />
    </RightSidebarLayout>
  );
};
