import clsx from 'clsx';

import styles from './InternalBlockMenu.module.scss';
import { InternalBlockMenuItem } from './InternalBlockMenuItem';

export const AboutBlockMenu = [
  { key: 1, name: 'all', href: '/about/', paramName: 'about' },
  {
    key: 2,
    name: 'advertise',
    href: '/page/advertise/',
    paramName: 'advertise',
  },
  {
    key: 3,
    name: 'contact',
    href: '/contact/',
    paramName: 'contact',
  },
  {
    key: 4,
    name: 'press',
    href: '/category/press/news/',
    paramName: 'press',
  },
  {
    key: 5,
    name: 'faq',
    href: 'https://on3.zendesk.com/hc/en-us',
    paramName: 'faq',
  },
];
export const NILBlockMenu = [
  {
    key: 1,
    name: 'resources',
    href: '/nil/resources/news/',
    paramName: 'resources',
  },
  {
    key: 2,
    name: 'high school rules',
    href: '/nil/laws/high-school/',
    paramName: 'high-school',
  },
  {
    key: 3,
    name: 'college rules',
    href: '/nil/laws/college/',
    paramName: 'college',
  },
];

export interface IInternalBlockMenuItemProps {
  key: number;
  name: string;
  href: string;
  paramName: string;
  isDark?: boolean;
  className?: string;
  activeClassName?: string;
}
interface IInternalBlockMenuProps {
  className?: string;
  list: IInternalBlockMenuItemProps[];
  isDark?: boolean;
  containerClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
}
export const InternalBlockMenu = ({
  list,
  isDark,
  containerClassName,
  itemClassName,
  activeClassName,
  className,
}: IInternalBlockMenuProps) => {
  return (
    <ul
      className={clsx(className, styles.internalContainer, containerClassName, {
        [styles.dark]: isDark,
      })}
    >
      {list.map((item) => {
        return (
          <InternalBlockMenuItem
            {...item}
            activeClassName={activeClassName}
            className={itemClassName}
            isDark={isDark}
            key={item.key}
          />
        );
      })}
    </ul>
  );
};
